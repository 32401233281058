import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { RedirectProps as Props } from "./Redirect.types";

const Redirect: React.FC<Props> = props => {
  const { pathname, redirect } = props;
  const { replace } = useRouter();

  useEffect(() => {
    const query = {
      redirect
    };
    if (!redirect) delete query.redirect;

    replace({ pathname, query });
  }, [pathname, replace, redirect]);

  return null;
};

Redirect.defaultProps = {};

export default Redirect;

import Head from "next/head";
import React from "react";

import Login from "components/login/Login/Login";
import { CONSTANTS } from "config/constants";

const { TITLE } = CONSTANTS;

interface Props {}

const LoginPage = (props: Props) => {
  return (
    <>
      <Head>
        <title>Iniciar sesión | {TITLE}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Login />
    </>
  );
};

export default LoginPage;

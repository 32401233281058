import { Storage } from "@capacitor/storage";
import { events } from "artisn/analytics";
import { getShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import Styles from "./SignIn.styles";
import { SignInProps as Props } from "./SignIn.types";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import SignInForm from "components/signIn/SignIn/SignInForm/SignInForm";
import { CONSTANTS } from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { usePostMigrateAnonymousOrders } from "services/orders/orders.service.hooks";
import { useFetchUser } from "@anefi/services";
import { notify, sanitizeQueryParams } from "utils/common.utils";
import { getFirebaseAuthErrorMessage } from "utils/common.utils";

const { logSignIn } = events.auth;
const { STORAGE, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const SignIn: React.FC<Props> = props => {
  const { className } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const { query, replace, asPath } = useRouter();
  const auth = useAuth();
  const { signInWithEmailAndPassword, isAnonymous } = auth;
  const { transferAnonymousId } = useCheckout();
  const { data: user } = useFetchUser(auth);
  const postMigrateAnonymousOrders = usePostMigrateAnonymousOrders();
  const { mutate: migrateAnonymousOrders } = postMigrateAnonymousOrders;
  const { redirect } = sanitizeQueryParams(query);
  const { analyticsInitialized } = useAnalytics();

  const handleRedirection = useCallback(() => {
    const target = `${redirect ?? "/"}`;
    if (asPath === target) return;
    replace(target);
  }, [asPath, redirect, replace]);

  const signIn = async (email: string, password: string) => {
    await signInWithEmailAndPassword(email, password);
    const { name, lastname } = user ?? {};
    logSignIn({
      method: "Email and Password",
      name: `${name} ${lastname}`
    });
  };

  const onSubmitSuccess = async (email: string, password: string) => {
    setErrorMessage("");
    try {
      const anonymousCart = await getShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });
      if (anonymousCart) {
        await Storage.set({
          key: ANONYMOUS_SHOPPING_CART_TOKEN,
          value: JSON.stringify(anonymousCart)
        });
      }
      await signIn(email, password);
      // Sometimes when an anonymous user registers at the checkout the
      // registration might fail for any reasons, in those cases you will want
      // transfer the anonymous orders to the user who is signing in
      if (transferAnonymousId) migrateAnonymousOrders();
    } catch (e) {
      notify(e, "login with Email and Password");
      setErrorMessage(getFirebaseAuthErrorMessage(e.code));
    }
  };

  const focusHandler = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (isAnonymous || !analyticsInitialized) return;
    handleRedirection();
  }, [isAnonymous, analyticsInitialized, handleRedirection]);

  return (
    <Styles className={`SignIn ${className}`}>
      <SignInForm
        focusHandler={focusHandler}
        onSubmitSuccess={onSubmitSuccess}
        errorMessage={errorMessage}
        className="SignIn__form"
      />
    </Styles>
  );
};

SignIn.defaultProps = {
  className: ""
};

export default SignIn;

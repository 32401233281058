// orders service hooks
import { useMutation, useQueryClient } from "react-query";
import { Vendor } from "artisn/types";
import { useVendors, useCountries } from "@anefi/contexts";
import { postMigrateAnonymousOrders } from "@anefi/services";

import useAuth from "contexts/auth/auth.context.hooks";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";

/**
 * Hook to migrate anonymous orders
 *
 * @param {number} vendorId
 * @returns The useMutation result of the orders migration
 */
export const usePostMigrateAnonymousOrders = (vendorId?: Vendor["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { transferAnonymousId, setTransferAnonymousId } = useCheckout();
  const { selectedVendorId } = useVendors();
  const { selectedCountryId } = useCountries();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useMutation(
    () => postMigrateAnonymousOrders(selectedCountryId, transferAnonymousId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          "IN_PROGRESS",
          chosenVendorId
        ]);
        queryClient.invalidateQueries([
          uid,
          "orders",
          "history",
          "DONE",
          chosenVendorId
        ]);
      },
      onSettled: () => {
        setTransferAnonymousId(undefined);
      }
    }
  );
};

import { Storage } from "@capacitor/storage";
import { getShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useFetchUser } from "@anefi/services";
import { events } from "artisn/analytics";

import Styles from "./SignUp.styles";
import { SignUpProps as Props } from "./SignUp.types";
import SignInForm from "components/signIn/SignIn/SignInForm/SignInForm";
import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { notify, sanitizeQueryParams } from "utils/common.utils";
import { getFirebaseAuthErrorMessage } from "utils/common.utils";
import Redirect from "components/global/Redirect/Redirect";

const { logSignUp } = events.auth;
const { SHOPPING_CART_DEFAULT_NAME, STORAGE } = CONSTANTS;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const SignUp: React.FC<Props> = props => {
  const { className } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useAuth();
  const { registerWithEmailAndPassword, isAnonymous } = auth;
  const { query } = useRouter();
  const { redirect } = sanitizeQueryParams(query);
  const { isFetched } = useFetchUser(auth);

  const onSubmitSuccess = async (email: string, password: string) => {
    setErrorMessage("");
    try {
      const anonymousCart = await getShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });
      const { user } = await registerWithEmailAndPassword(email, password);
      logSignUp({
        email,
        method: "Email and Password",
        name: user?.displayName ?? ""
      });
      if (anonymousCart) {
        await Storage.set({
          key: ANONYMOUS_SHOPPING_CART_TOKEN,
          value: JSON.stringify(anonymousCart)
        });
      }
    } catch (e) {
      notify(e, "Register with Email and Password");
      setErrorMessage(getFirebaseAuthErrorMessage(e.code));
    }
  };

  const focusHandler = () => {
    setErrorMessage("");
  };

  if (!isAnonymous && isFetched) {
    const target = `${redirect ?? "/"}`;
    return <Redirect pathname={target} redirect={redirect} />;
  }

  return (
    <Styles className={`SignUp ${className}`}>
      <SignInForm
        focusHandler={focusHandler}
        errorMessage={errorMessage}
        currentForm="signUp"
        buttonTitle="Continuar"
        onSubmitSuccess={onSubmitSuccess}
        className="SignUp__form"
      />
    </Styles>
  );
};

SignUp.defaultProps = {
  className: ""
};

export default SignUp;

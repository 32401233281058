import styled from "styled-components";

import { GoBackStyledProps as Props } from "./GoBack.types";

const GoBackStyled = styled.div<Props>`
  width: ${props => (props.title ? "auto" : "6rem")};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .GoBack {
    &__icon {
      display: flex;
      align-items: center;
    }

    &__title {
      display: inline-block;
      width: ${props => (props.width ? props.width + "px" : "auto")};
      padding-left: 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }
`;

export default GoBackStyled;

import { ButtonGroup } from "artisn-ui-react";
import React, { useState } from "react";

import Styles from "./Login.styles";
import { LoginProps as Props, ActiveState } from "./Login.types";
import GoBack from "components/global/GoBack/GoBack";
import SignIn from "components/signIn/SignIn/SignIn";
import SignUp from "components/signUp/SignUp/SignUp";

import LogoWhiteSVG from "../../../../public/assets/images/anefi/logo-white.svg";

const { Button: ButtonG } = ButtonGroup;

const Login: React.FC<Props> = props => {
  const { className } = props;
  const [active, setActive] = useState<ActiveState>("signIn");

  return (
    <Styles className={`Login ${className}`} active={active}>
      <header className="Login__header" />
      <main className="Login__main">
        <div className="Login__logo">
          <LogoWhiteSVG />
        </div>
        <div className="Login__content">
          <div className="Login__center">
            <GoBack className="Login__goBack" />
            <p className="Login__title">Ingresa con tu correo</p>
            <ButtonGroup
              className="Login__tabs"
              defaultActive="anonimo"
              onChange={e => setActive(e.target.value)}
              active={active}
            >
              <ButtonG
                className="Login__tabs__button--signIn"
                value={"signIn"}
                id="1"
              >
                Iniciar sesión
              </ButtonG>
              <ButtonG
                className="Login__tabs__button--signUp"
                value={"signUp"}
                id="2"
              >
                Crear cuenta
              </ButtonG>
            </ButtonGroup>
            {active === "signIn" ? (
              <SignIn className="Login__signIn" />
            ) : (
              <SignUp className="Login__signUp" />
            )}
          </div>
        </div>
      </main>
      <footer className="Login__footer" />
    </Styles>
  );
};

Login.defaultProps = {
  className: ""
};

export default Login;

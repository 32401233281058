import styled from "styled-components";

import { SignInStyledProps as Props } from "./SignIn.types";

const SignInStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  display: flex;
  flex-direction: column;
  background-color: var(--palette-gray-s0-l97);
  flex: 1;
  display: grid;
  grid-template-columns:
    minmax(1.6rem, 1fr)
    minmax(0, 60rem)
    minmax(1.6rem, 1fr);
  grid-template-areas: ".      form    .";

  .SignIn {
    &__form {
      grid-area: form;
      overflow: hidden;
    }
  }
`;

export default SignInStyled;

import styled from "styled-components";

import { LoginStyledProps as Props } from "./Login.types";
import { CONSTANTS } from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const LoginStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-gray-s0-l97);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;

  .Login {
    &__header {
      grid-column: fullBleed;
    }

    &__title {
      color: var(--palette-black);
      font-size: 2.8rem;
      font-weight: 600;
      padding-bottom: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: ${mobile}px) {
        font-size: 2.4rem;
      }
    }

    &__main {
      grid-column: fullBleed;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: ${tablet}px) {
        grid-template-rows: 10rem 1fr;
        grid-template-columns: 1fr;
      }
    }

    &__logo {
      background-color: var(--palette-black-s0-l15);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: ${tablet}px) {
        max-height: 10rem;
      }
    }

    &__content {
      padding: 0 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: ${mobile}px) {
        padding: 2rem;
      }
    }

    &__goBack {
      padding-bottom: 2rem;

      @media (max-width: ${mobile}px) {
        padding-top: 2rem;
      }
    }

    &__content {
      overflow: hidden;
    }

    &__tabs {
      padding-bottom: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &__button {
        &--signIn {
          flex: 1;
          border: none;
          box-shadow: none;
          font-size: 1.6rem;
          font-weight: 600;
          color: ${props =>
            props.active === "signIn"
              ? "var(--palette-secondary-s40-l45)"
              : "var(--palette-black)"};
          border-bottom: ${props =>
            props.active === "signIn"
              ? "0.2rem solid var(--palette-secondary-s40-l45)"
              : "0.2rem solid var(--palette-gray-s0-l80)"};

          &:hover {
            border-bottom: 0.2rem solid var(--palette-secondary-s40-l45);
            color: var(--palette-secondary-s40-l45);
          }

          @media (max-width: ${mobile}px) {
            font-size: 1.4rem;
          }
        }

        &--signUp {
          flex: 1;
          border: none;
          box-shadow: none;
          font-size: 1.6rem;
          font-weight: 600;
          color: ${props =>
            props.active === "signUp"
              ? "var(--palette-secondary-s40-l45)"
              : "var(--palette-black)"};
          border-bottom: ${props =>
            props.active === "signUp"
              ? "0.2rem solid var(--palette-secondary-s40-l45)"
              : "0.2rem solid var(--palette-gray-s0-l80)"};

          &:hover {
            border-bottom: 0.2rem solid var(--palette-secondary-s40-l45);
            color: var(--palette-secondary-s40-l45);
          }

          @media (max-width: ${mobile}px) {
            font-size: 1.4rem;
          }
        }
      }
    }

    &__signIn {
      animation: AppearLeft 1s;
    }

    &__signUp {
      animation: AppearRight 1s;
    }

    &__footer {
      grid-column: fullBleed;
    }
  }
`;

export default LoginStyled;
